@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'RNS Sanz';
  src: url('../public/fonts/rns-sanz/RNSSanz-Bold.eot');
  src: local('RNS Sanz Bold'), local('RNSSanz-Bold'),
      url('../public/fonts/rns-sanz/RNSSanz-Bold.eot?#iefix') format('embedded-opentype'),
      url('../public/fonts/rns-sanz/RNSSanz-Bold.woff2') format('woff2'),
      url('../public/fonts/rns-sanz/RNSSanz-Bold.woff') format('woff'),
      url('../public/fonts/rns-sanz/RNSSanz-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('../public/fonts/rns-sanz/RNSSanz-ExtraBold.eot');
  src: local('RNS Sanz ExtraBold'), local('RNSSanz-ExtraBold'),
      url('../public/fonts/rns-sanz/RNSSanz-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../public/fonts/rns-sanz/RNSSanz-ExtraBold.woff2') format('woff2'),
      url('../public/fonts/rns-sanz/RNSSanz-ExtraBold.woff') format('woff'),
      url('../public/fonts/rns-sanz/RNSSanz-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('../public/fonts/rns-sanz/RNSSanz-SemiBold.eot');
  src: local('RNS Sanz SemiBold'), local('RNSSanz-SemiBold'),
      url('../public/fonts/rns-sanz/RNSSanz-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../public/fonts/rns-sanz/RNSSanz-SemiBold.woff2') format('woff2'),
      url('../public/fonts/rns-sanz/RNSSanz-SemiBold.woff') format('woff'),
      url('../public/fonts/rns-sanz/RNSSanz-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('../public/fonts/rns-sanz/RNSSanz-Light.eot');
  src: local('RNS Sanz Light'), local('RNSSanz-Light'),
      url('../public/fonts/rns-sanz/RNSSanz-Light.eot?#iefix') format('embedded-opentype'),
      url('../public/fonts/rns-sanz/RNSSanz-Light.woff2') format('woff2'),
      url('../public/fonts/rns-sanz/RNSSanz-Light.woff') format('woff'),
      url('../public/fonts/rns-sanz/RNSSanz-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz Normal';
  src: url('../public/fonts/rns-sanz/RNSSanz-Normal.eot');
  src: local('RNS Sanz Normal'), local('RNSSanz-Normal'),
      url('../public/fonts/rns-sanz/RNSSanz-Normal.eot?#iefix') format('embedded-opentype'),
      url('../public/fonts/rns-sanz/RNSSanz-Normal.woff2') format('woff2'),
      url('../public/fonts/rns-sanz/RNSSanz-Normal.woff') format('woff'),
      url('../public/fonts/rns-sanz/RNSSanz-Normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: "RNS Sanz", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #131416;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
