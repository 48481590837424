.know {
    position: relative;
    padding: 10vh 0;

    &__graphic {
        position: absolute;
        width: 40dvw;
        left: -10px;
        top: 0;
    }

    &__title {
        text-transform: uppercase;
        font-family: 'RNS Sanz';
        padding: 0 5vw;
        margin-bottom: 100px;

        &_basic {
            color: #fff;
            font-weight: 100;
        }

        &_highlighted {
            color: #9E43E4;
            font-weight: bold;
        }
    }

    &__cards {
        display: flex;
        width: 100vw;
        margin-bottom: 100px;
    }

    &__progress {
        width: 80%;
        margin: auto;
    }

    .slick-list {
        padding: 0 50px;
    }
}
