.sticky-navbar-nav {
    padding: 0 !important;
}

.topNavbar {
    background-color: #00000080;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10vw;
    width: 100%;
    z-index: 10;

    @media (max-width: 768px) {
        padding: 0 5vw;
    }

    &__brand {
        padding: 5px;

        &_image {
            width: auto;
            height: 50px;
        }
    }

    &__button {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        text-transform: uppercase;
        padding: 10px 40px;
        border-radius: 5px;
        background: linear-gradient(90deg, #7815AB 0%, #2167C0 100%);
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        border: none;

        &:hover {
            transform: translateY(2px);
            box-shadow: 0px 0px 20px 0px #a57cdb;
        }
    }
}
