.slider__arrow {
    position: absolute;
    top: calc(50% - 60px);
    color: #9E43E4;
    font-size: 60px;
    z-index: 2;
    cursor: pointer;

    &_right {
        right: 0;
        transform: rotate(180deg);
    }

    &_left {
        left: 0;
    }
}
