.progress {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100px;
    position: relative;
    bottom: 20px;
    background-color: transparent;
    margin: 40px 0;

    @media (max-width: 768px) {
        position: relative;
        width: 100%;
        margin-top: 70px;
    }

    &_title {
        position: absolute;
        text-transform: uppercase;
        color: #fff;
        font-weight: 100;
        top: 0;

        span {
            color: #09ADF4;
            font-weight: 700;
        }
    }

    &_label {
        margin-bottom: 40px;
        color: #fff;
        position: absolute;
        font-weight: bold;
        font-size: 14px;
        margin-right: -17px;

        &::after {
            content: 'R$';
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 13px;
            height: 13px;
            background: #fff;
            color: #0A50C3;
            border-radius: 100%;
            font-size: 9px;
            top: 24px;
            left: 11px;
        }
    }

    &_wrapper {
        border: solid 1px #AAAEB4;
        border-radius: 10px;
        margin-top: 25px;
        height: 18px;
        width: 100%;
    }
}
