.card {
    border-radius: 15px;
    background: linear-gradient(32deg, #6b13a180 11.2%, #2567c380 96.3%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 10px;
    height: 330px;
    width: 255px;
    margin: 5px;
    border: solid 1px #C47EFA;
    mix-blend-mode: screen;

    &__text {
        color: #fff;
        font-size: 2rem;

        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin: 0;

        &.has-multiplier {
            font-size: 2.5rem;

            &::before {
                content: '12x';
                font-size: 1rem;
                color: #fff;
                font-weight: 100;
                margin-right: 5px;
            }
        }
    }

    &__separator {
        width: 90%;
        height: 1px;
        background-color: #ccc;
    }

    &__button {
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        padding: 10px 30px;
        background: linear-gradient(90deg, #6C109F 0.18%, #1F6DC5 99.94%);
        transition: all 0.2s ease-in-out;
        color: #fff;
        font-weight: 700;

        &:hover {
            transform: translateY(2px);
            box-shadow: 0px 0px 20px 0px #a57cdb;
        }
    }

    &__bonus {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-transform: uppercase;

        &_title {
            font-weight: bold;
            font-size: 18px;
            margin: 0;
        }

        &_text {
            font-weight: 100;
            font-size: 12px;
            margin: 0;
            margin-left: 10px;
        }
    }
}
