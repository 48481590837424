.knowCard {
    position: relative;
    width: 320px;
    display: block;
    text-decoration: none;
    padding: 40px 20px;
;

    &:hover {
        .knowCard__back_element {
            left: -29%;
        }

        .knowCard__container {
            // transform: rotateY(180deg);
            margin-left: 30px;
        }

        .flip {
            transform: rotateY(180deg);
        }
    }

    &__back_element {
        position: absolute;
        font-family: 'Roboto Condensed', sans-serif;
        color: #000;
        font-size: 500px;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
        -webkit-text-stroke-width: 10px;
        -webkit-text-stroke-color: transparent;
        -webkit-text-fill-color: #000;

        background-image: linear-gradient(180deg,#0EA8FF 0%,#CA32FF 52%,#2207C8 100%);
        background-clip: text;
        opacity: 0.7;
        left: -30%;
        transition: all 0.4s ease-in-out;
        top: calc(50% - 250px);
    }

    &__container {
        position: relative;
        margin-left: 40px;
        width: 300px;
        height: 600px;
        perspective: 1000px;
        transition: all 0.3s ease-in-out;
        box-shadow: #c832ffa9 1px 1px 50px;
        background: linear-gradient(150deg, #7414BF 0%, #020B2A 100%);
        border-radius: 10px;

        @media (max-width: 768px) {
            width: 250px;
            height: 500px;
        }

        &.flip {
            perspective: 1000px;
            transform-style: preserve-3d;
        }

        &__front {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            backface-visibility: hidden;

            &.reversed {
                flex-direction: column !important;
                align-items: flex-start;


                .knowCard__container__front__text {
                    order: 1;
                    font-size: 20px;
                    margin-left: 25px;
                }

                .knowCard__container__front__text_highlighted {
                    order: 0;
                    margin-left: 25px !important;
                }

            }

            &__bg {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }

            &__hashtag {
                text-transform: uppercase;
                position: absolute;
                top: 20px;
                color: transparent;
                background-image: linear-gradient(95deg,#0EA8FF 0%,#CA32FF 100%);
                background-clip: text;
                width: 100%;
                text-align: center;
            }

            &__text {
                font-weight: 100;
                font-size: 40px;
                color: #fff;
                text-transform: uppercase;

                &_highlighted {
                    font-weight: bold;
                    color: #09B5FF;
                    font-size: 40px;
                    text-transform: uppercase;
                }
            }
        }

        &__back {
            border-radius: 10px;
            background-color: #fff;
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
            top: 0;
            transform: rotateY(180deg);
            backface-visibility: hidden;

            &__title {
                color: #7414BF;
            }

            &__text {
                color: #7414BF;
            }
        }
    }

}
