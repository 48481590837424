.hero {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url('../../../../public/images/hero-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px 10vw;
    flex-wrap: wrap;
    min-height: 100dvh;

    @media (max-width: 768px) {
        padding: 20vw 10vw;
    }


    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 45%;
        min-width: 300px;

        &_title {
            font-size: 5rem;
            letter-spacing: -4px;
            color: #fff;
            margin-bottom: 1rem;
            text-transform: uppercase;
            font-family: 'Roboto Condensed', sans-serif;
            margin: 0;
        }

        &_subtitle {
            font-size: 1.3rem;
            color: #fff;
            margin-bottom: 1rem;
            text-transform: uppercase;
            margin: 0;
            font-style: normal;
            font-weight: 700;
            max-width: 430px;
        }

        &_text {
            color: #AAAEB4;
            font-size: 1rem;
            font-weight: 600;
            max-width: 410px;
        }

        &_button {
            margin-top: 30px;
            margin-bottom: 30px;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: bold;
            font-size: 2rem;
            border: none;
            color: #ffffff;
            text-transform: uppercase;
            padding: 10px 0px;
            width: 100%;
            text-align: center;
            border-radius: 5px;
            background: linear-gradient(90deg, #7815AB 0%, #2167C0 100%);
            text-decoration: none;
            transition: all 0.2s ease-in-out;
            z-index: 1;

            &:hover {
                transform: translateY(2px);
                box-shadow: 0px 0px 20px 0px #a57cdb;
            }
        }
    }

    &__media {
        width: 50%;
        min-width: 300px;
        position: relative;
        cursor: pointer;

        &_image {
            width: 100%;
        }

        &_icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            .hero__media_icon {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

    }

    &__left-light {
        position: absolute;
        left: 0px;
        background: rgba(151, 71, 255, 0.70);
        filter: blur(140px);
        border-radius: 100%;
        width: 250px;
        height: 250px;
        z-index: 0;
    }

    &__right-light {
        position: absolute;
        right: 0px;
        background: rgba(32, 104, 193, 0.70);
        filter: blur(140px);
        border-radius: 100%;
        width: 250px;
        height: 250px;
        z-index: 0;
    }

    &__bottom_banner-box {
            display: grid;
            place-content: center;

            --border-angle: 0turn; // For animation.
            --main-bg: conic-gradient(
                from var(--border-angle),
                #213,
                #112 5%,
                #112 60%,
                #213 95%
              );

            border: solid 3px transparent;
            // --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, rgba(151, 71, 255, 0.70), rgba(32, 104, 193, 0.70) 99%, transparent);
            --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #f26b11, rgba(255, 255, 255, 0.7) 99%, transparent);

            background:
              // padding-box clip this background in to the overall element except the border.
              var(--main-bg) padding-box,
              // border-box extends this background to the border space
              var(--gradient-border) border-box,
              // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
              var(--main-bg) border-box;

            background-position: center center;

            animation: bg-spin 3s linear infinite;
            @keyframes bg-spin {
              to {
                --border-angle: 1turn;
              }
            }

            // &:hover {
            //   animation-play-state: paused;
            // }
          }

    &__bottom_banner {
        width: 100%;

        @media (max-width: 768px) {
        }
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}
