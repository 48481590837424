.estrutura {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 90dvh;
    flex-wrap: nowrap;
    margin-top: 50px;

    &__media {
      width: 50%;
      height: 100vh;
      display: flex;
      align-items: center;
      min-width: 300px;
      position: relative;

      &::after {
        content: "Imagem meramente ilustrativa";
        color: #fff;
        position: absolute;
        right: 10px;
        top: calc(100% - 11%);

        @media (max-width: 1870px) {
          top: calc(100% - 16%);
        }

        @media (max-width: 1645px) {
          top: calc(100% - 21%);
        }

        @media (max-width: 1430px) {
          top: calc(100% - 28%);
        }
      }

      img {
        width: 100%;
        height: auto;
        margin: auto 0;
      }
    }

    &__content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 45%;
      min-width: 300px;
      min-height: 90dvh;
      padding: 10vh 0;
      padding-right: 10dvw;

      &_graphic {
        position: absolute;
        width: 400px;
        right: 0;
        height: 7px;
        border-radius: 5px;
        background: linear-gradient(90deg, #0390CD 0%, #26409D 50%, #8F2EB1 100%);
        top: 20px;
      }

      &_title {
        color: #26409D;
        font-family: "RNS Sanz";
        font-weight: 300;
        font-size: 52px;
        line-height: 100%;
        letter-spacing: -2px;
        margin-bottom: 30px;

        .highlighted {
          font-weight: bold;
        }
      }

      &_text {
        color: #401660;
        font-weight: normal;
        font-family: 'RNS Sanz';
      }
    }

    &__white-bg {
      position: absolute;
      background-color: #fff;
      height: 100%;
      width: 90dvw;
      right: 0;
      z-index: -1;
    }

    @media (max-width: 1080px) {
      flex-direction: column;
      align-items: center;
      background-color: #fff;

      &__media {
        width: 90% !important;
        height: auto !important;
        margin-top: 30px !important;

        &::after {
          top: calc(100% - 30px);
        }
      }

      &__content {
        width: 90% !important;

        &_title {
          font-size: 3rem !important;
        }

        &_graphic {
          width: 70% !important;
          right: -10% !important;
        }
      }

      &__white-bg {
        display: none;
      }
    }
  }

  .depoimentos {
    width: 100%;
    padding: 10vh 0;

    &__separator {
      width: 50%;
      margin: auto;
    }

    &__title {
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      margin: 30px;
      margin-top: 100px;

      .highlighted {
        font-weight: bold;
        color: #0390CD;
      }
    }

    &__item {
      padding: 30px;
      width: 550px !important;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      @media (max-width: 920px) {
        width: 300px !important;
        padding: 20px;
      }

      &_image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover {
        transform: translateY(2px);

        .depoimentos__item_icon {
          opacity: 1 !important;
        }
      }
    }

    .slick-slider {
      max-height: 400px;
    }

    .slick-track {
      display: flex;
      flex-wrap: nowrap;
    }

    .slick-slide {
      transition: transform 0.3s;
      width: 550px !important;
    }

    .slick-current {
      transform: scale(1.15);
    }
  }

  .callout {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F1F1;
    padding: 100px;
    flex-direction: column;

    &__text {
      color: #131416;
      font-family: 'RNS Sanz';
      font-weight: 100;
      text-transform: uppercase;
      font-size: 3rem;
      padding: 20px;

      &_highlighted {
        font-weight: bold;
        color: #401660;
      }
    }

    &__button {
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: bold;
      font-size: 2rem;
      border: none;
      color: #ffffff;
      text-transform: uppercase;
      padding: 10px 0px;
      width: 300px;
      text-align: center;
      border-radius: 5px;
      background: linear-gradient(90deg, #7815AB 0%, #2167C0 100%);
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:hover {
          transform: translateY(2px);
          box-shadow: 0px 0px 20px 0px #a57cdb;
      }
    }
  }

  .footer {
    background-color: #131416;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
