.modal {
    max-width: 100vw;

    &-dialog {
        border-radius: 10px;
    }

    &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 90dvh;
        // background-image: url('../../../../public/images/modal-bg.png');
        color: #fff;
        background-color: #131416;
        border-radius: 10px;
        padding: 40px 10px;
        background-image: url('../../../../public/images/hero-bg.png');
        background-repeat: no-repeat;
        background-size: cover;

        .btn-close {
            position: absolute;
            top: 10px;
            right: 10px;
            filter: invert(1);
        }
    }

    &__title {
        font-weight: 100;
        text-align: center;

        span {
            color: #09ADF4;
            font-weight: 700;
        }
    }

    &__blocks {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &__header {
            border-radius: 10px;
            width: 99%;
            color: #fff;
            padding: 5px 5px;
            background: #A268C7;
            text-transform: uppercase;
        }

        @media (max-width: 1200px) {
            flex-direction: column;
        }
    }

    &__second_block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 10px;
    }

    &__cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        @media (max-width: 1200px) {
            flex-direction: column;
        }
    }
}
