.modal {
    &-dialog {
        border-radius: 10px;
    }

    &-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 90dvh;
        // background-image: url('../../../../public/images/modal-bg.png');
        color: #fff;
        background-color: #131416;
        border-radius: 10px;

        .btn-close {
            position: absolute;
            top: 10px;
            right: 10px;
            filter: invert(1);
            z-index: 10;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
