.greetings {
    height: 100dvh;
    max-width: 100dvw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../../../public/images/hero-bg.png');
    position: relative;
    padding: 20px;
    overflow-x: hidden;

    &__left-light {
        position: absolute;
        left: -100px;
        background: rgba(151, 71, 255, 0.50);
        filter: blur(140px);
        border-radius: 100%;
        width: 30%;
        height: 100%;
        z-index: 2;
    }

    &__right-light {
        position: absolute;
        right: -100px;
        background: rgba(32, 104, 193, 0.50);
        filter: blur(140px);
        border-radius: 100%;
        width: 30%;
        height: 100%;
        z-index: 2;
    }

    &__title {
        font-weight: 900;
        font-family: "Roboto Condensed";
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }

    &__text {
        color: #fff;
        font-weight: lighter;
        text-transform: uppercase;
        text-align: center;
        margin-top: 20px;
        font-size: 1.5em;
        max-width: 800px;
    }

    iframe {
        border-radius: 10px;
        top: 0;
        left: 0;
        width: 60%;
        height: 70%;
        z-index: 1;

        @media (max-width: 1080px) {
            width: 100%;
            height: 100%;
        }
    }
}
